import { navigate } from 'gatsby';
import React, { createContext, useState } from 'react';

const initialContext = {
    query: '',
    setQuery: (searchQuery) => { },
    showSearch: false,
    setShowSearch: () => { }
};

const SearchContext = createContext(initialContext);

export function SearchContextProvider({ children }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearch, setShowSearch] = useState(false);

    function changeSearchQuery(searchQuery) {
        //if (!searchQuery) return;
        if (searchQuery === 'none' || searchQuery === '') {
            setSearchQuery('');
            return;
        }
        setSearchQuery(searchQuery);
        const path = window.location.pathname;
        if (path.length > 1) {
            navigate('/');
        }
    }

    const context = {
        query: searchQuery,
        setQuery: changeSearchQuery,
        showSearch,
        setShowSearch
    };

    return <SearchContext.Provider value={context}>{children}</SearchContext.Provider>;
}

export default SearchContext;